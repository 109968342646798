// extracted by mini-css-extract-plugin
export var footer = "shishimai2-module--footer--ad5c2";
export var footerH2 = "shishimai2-module--footerH2--0e70a";
export var h2 = "shishimai2-module--h2--dacbb";
export var h3 = "shishimai2-module--h3--ebf3d";
export var h3MySuffix = "shishimai2-module--h3MySuffix--bc883";
export var h4 = "shishimai2-module--h4--6fdda";
export var itemBlock = "shishimai2-module--itemBlock--eec58";
export var leftPane = "shishimai2-module--leftPane--4a45f";
export var main = "shishimai2-module--main--f8f2b";
export var menuBox = "shishimai2-module--menuBox--b65cf";
export var menuContentBox = "shishimai2-module--menuContentBox--c5446";
export var menuLi = "shishimai2-module--menuLi--8f8ab";
export var menuSelectedLi = "shishimai2-module--menuSelectedLi--09766";
export var menuTitle = "shishimai2-module--menuTitle--bfd34";
export var menuUl = "shishimai2-module--menuUl--88094";
export var nendoTable = "shishimai2-module--nendoTable--8fb52";
export var noteH2 = "shishimai2-module--noteH2--df07d";
export var noteLi = "shishimai2-module--noteLi--07c2c";
export var noteUl = "shishimai2-module--noteUl--84d50";
export var noticeBox = "shishimai2-module--noticeBox--81c1f";
export var p = "shishimai2-module--p--1dabe";
export var paragraph = "shishimai2-module--paragraph--5a71d";
export var tableDiv = "shishimai2-module--tableDiv--941f7";
export var tocDesc = "shishimai2-module--tocDesc--cc0e3";
export var tocLi = "shishimai2-module--tocLi--16a8e";
export var tocUl = "shishimai2-module--tocUl--8d0d9";
export var visitedul = "shishimai2-module--visitedul--6299c";
export var visitli = "shishimai2-module--visitli--be1fc";
export var wrapper = "shishimai2-module--wrapper--bbd15";
/** @jsxImportSource theme-ui */

import { Link } from "gatsby";
import { formatTime, MyDate } from "../utils/datetimeutils";
import ExternalLink from "./external-link";

interface EventRowNode {
  name?: string | null;
  namePrefix?: string | null;
  nameSuffix?: string | null;
  canceled?: string | null;
  locationName?: string | null;
  locationSubName?: string | null;
  sources?: string | null;

  dateObj?: MyDate;
  endDateObj?: MyDate;
  openTimeObj?: Date;
  startTimeObj?: Date;
  endTimeObj?: Date;
}

type EventRowProps = {
  node: EventRowNode;
  refUrl?: string;
  skipped: boolean;
  isClassic: boolean;
};

const EventRow: React.FC<EventRowProps> = (props) => {
  const node = props.node;
  const refUrl = props.refUrl;
  const skipped = props.skipped;

  const isClassic = props.isClassic ? true : false;

  const name = `${node.canceled || ""}${node.namePrefix || ""}${node.name || ""}${node.nameSuffix || ""}`;
  const canceledSuffix = node.canceled ? " " + node.canceled.trim().replace("（", "").replace("）", "") : "";
  const dateStr = createDateStr(node.dateObj, node.endDateObj);
  const timeStr = createTimeStr(node.openTimeObj, node.startTimeObj, node.endTimeObj);
  const location = `${node.locationName || ""}${node.locationSubName || ""}`;

  if (!skipped) {
    return (
      <tr sx={isClassic ? trc : tr}>
        <td sx={isClassic ? firstTdc : firstTd}>
          {refUrl
            ? <Link to={refUrl}>{name}</Link>
            : (
              <>
                {name}
              </>
            )}
        </td>
        <td sx={isClassic ? tdc : td}>
          {(!dateStr && !timeStr)
            ? (
              <>
                <span sx={isClassic ? subheadc : subhead}>
                  日時：<br />
                </span>
                <span sx={isClassic ? noinfoc : noinfo}>（情報なし）</span>
              </>
            )
            : (
              <>
                <span sx={isClassic ? subheadc : subhead}>
                  日時：<br />
                </span>
                {node.canceled
                  ? (
                    <>
                      <del sx={canceled}>
                        {dateStr}
                        {timeStr
                          && (
                            <>
                              <br />
                              {timeStr}
                            </>
                          )}
                      </del>
                      <br />
                      {canceledSuffix}
                    </>
                  )
                  : (
                    <>
                      {dateStr}
                      {timeStr
                        && (
                          <>
                            <br />
                            {timeStr}
                          </>
                        )}
                    </>
                  )}
              </>
            )}
        </td>
        <td sx={isClassic ? tdc : td}>
          {location
            && (
              <>
                <span sx={isClassic ? subheadc : subhead}>
                  場所：<br />
                </span>
                {location}
              </>
            )}
        </td>
        <td sx={isClassic ? tdc : td}>
          {node.sources
            && (
              <>
                <span sx={isClassic ? subheadc : subhead}>
                  参照：<br />
                </span>
                <ul sx={isClassic ? sourceListc : sourceList}>
                  {node.sources.split("\n").map(source => {
                    const s = source.split("http"); // should work for both http and https
                    if (s.length === 2) {
                      const text = s[0];
                      const url = `http${s[1]}`;
                      return (
                        <li key={`${text}_${url}`}>
                          <ExternalLink url={url} classic={isClassic}>{text}</ExternalLink>
                        </li>
                      );
                    } else {
                      return <li key={source}>{source}</li>;
                    }
                  })}
                </ul>
              </>
            )}
        </td>
      </tr>
    );
  } else {
    return (
      <tr sx={isClassic ? trc : tr}>
        <td sx={isClassic ? firstTdc : firstTd}>
          {refUrl
            ? <Link to={refUrl}>{name}</Link>
            : (
              <>
                {name}
              </>
            )}
        </td>
        <td sx={isClassic ? tdc : td}>（今年度の開催なし）</td>
        <td sx={isClassic ? tdc : td}></td>
        <td sx={isClassic ? tdc : td}></td>
      </tr>
    );
  }
};

export default EventRow;

const tr = {
  width: "100%",
};

const firstTd = {
  padding: "8px",
  borderBottom: "1px solid",
  borderRight: "1px solid",
  verticalAlign: "middle",

  "@media screen and (max-width: 600px)": {
    backgroundColor: "#F0E68C",
    display: "block",
    width: "100%",
    padding: "0.3rem",
  },
};

const td = {
  padding: "8px",

  "@media screen and (min-width: 601px)": {
    borderRight: "1px solid",
    borderBottom: "1px solid",
    verticalAlign: "middle",
  },
  "@media screen and (max-width: 600px)": {
    padding: "0.3rem",
    display: "block",
    width: "100%",
    borderRight: "1px solid",
  },

  "&:last-child": {
    "@media screen and (max-width: 600px)": {
      padding: "0.3rem",
      display: "block",
      width: "100%",
      borderRight: "1px solid",
      borderBottom: "1px solid",
      paddingBottom: "1.0rem",
    },
  },
};

const subhead = {
  "@media screen and (min-width: 601px)": {
    display: "none",
  },
  "@media screen and (max-width: 600px)": {
    fontWeight: "bold",
  },
};

const noinfo = {
  "@media screen and (min-width: 601px)": {
    display: "none",
  },
};

const canceled = {
  textDecoration: "line-through",
};

const sourceList = {
  listStyleType: "none",

  "li": {
    "@media screen and (min-width: 601px)": {
      marginBottom: "4px",
    },
    "@media screen and (max-width: 600px)": {
      marginBottom: "0.3rem",
    },
  },
};

/* tbodyTrStyle */
const trc = {
  backgroundColor: "#FFFFF0",
};

/* tdCommonStyle */
const firstTdc = {
  borderBottom: "1px solid",
  borderRight: "1px solid",
  padding: "8px",
  verticalAlign: "middle",
};

/* tdCommonStyle */
const tdc = {
  borderBottom: "1px solid",
  borderRight: "1px solid",
  padding: "8px",
  verticalAlign: "middle",
};

const subheadc = {
  display: "none",
};

const noinfoc = {
  display: "none",
};

/* sourcesUlStyle, sourcesLiStyle */
const sourceListc = {
  listStyleType: "none",
  margin: 0,
  padding: 0,

  "li": {
    paddingBottom: "4px",
  },
};

function createDateStr(dateObj?: MyDate, endDateObj?: MyDate) {
  if (dateObj && endDateObj) {
    return `${dateObj.formatInJapanese(true, true)}～${endDateObj.formatInJapanese(true, true)}`;
  } else if (dateObj) {
    return dateObj.formatInJapanese(true, true);
  } else {
    return "";
  }
}

function createTimeStr(openTimeObj?: Date, startTimeObj?: Date, endTimeObj?: Date) {
  if (startTimeObj) {
    if (endTimeObj && openTimeObj) {
      return `${formatTime(startTimeObj)}～${formatTime(endTimeObj)} (開場${formatTime(openTimeObj)})`;
    } else if (endTimeObj) {
      return `${formatTime(startTimeObj)}～${formatTime(endTimeObj)}`;
    } else if (openTimeObj) {
      return `開場${formatTime(openTimeObj)}, 開演${formatTime(startTimeObj)}`;
    } else {
      return `開演${formatTime(startTimeObj)}`;
    }
  } else {
    if (openTimeObj) {
      return `開場${formatTime(openTimeObj)}`;
    } else {
      return "";
    }
  }
}

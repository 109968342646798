/** @jsxImportSource theme-ui */

import { ThemeUICSSObject } from "theme-ui";
import { createMyDate, createTime, getNendo, MyDate } from "../utils/datetimeutils";
import { createShishimaiTaikaiPath } from "../utils/linkutils";
import EventRow from "./event-row";

interface SmetadataNode {
  taikaiId: string | null;
  nameOfficial: string | null;
  nameSuffix: string | null;
}

interface SdataNode {
  taikaiId: string | null;
  name: string | null;
  date: string | null;
  isFest: number | null;
  endDate: string | null;
  isEndDateFest: number | null;
  openTime: string | null;
  startTime: string | null;
  endTime: string | null;
}

type ThisNendoTableProps = {
  metadata: readonly { node: SmetadataNode }[];
  data: readonly { node: SdataNode }[];
  today: Date;
  isAnchorLink: boolean;
  isClassic?: boolean;
};

interface SdataNodeWithObjects extends SdataNode {
  dateObj?: MyDate;
  endDateObj?: MyDate;
  openTimeObj?: Date;
  startTimeObj?: Date;
  endTimeObj?: Date;
}

interface SdataWithObjects {
  node: SdataNodeWithObjects;
}

const ThisNendoTable: React.FC<ThisNendoTableProps> = (props) => {
  const taikaiList = props.metadata;
  const today = props.today;
  const isAnchorLink = props.isAnchorLink;
  const thisNendo = getNendo(today);
  const isClassic = props.isClassic ? true : false;

  const entries = props.data;
  const dataWithObjects: SdataWithObjects[] = entries.map(({ node }) => {
    const s: SdataWithObjects = {
      node: node,
    };
    s.node.dateObj = createMyDate(node.date, node.isFest);
    s.node.endDateObj = createMyDate(node.endDate, node.isEndDateFest);
    s.node.openTimeObj = createTime(node.openTime);
    s.node.startTimeObj = createTime(node.startTime);
    s.node.endTimeObj = createTime(node.endTime);
    return s;
  });
  const thisNendoEntries = dataWithObjects.filter(({ node }) => {
    return isDateInThisNendo(node.dateObj, thisNendo);
  });

  return (
    <table sx={isClassic ? tablec : table}>
      <thead sx={isClassic ? headc : head}>
        <tr>
          <th style={{ width: "30%" }}>名前</th>
          <th style={{ width: "20%" }}>日時</th>
          <th style={{ width: "20%" }}>場所</th>
          <th style={{ width: "30%" }}>参照</th>
        </tr>
      </thead>
      <tbody sx={isClassic ? bodyc : body}>
        {taikaiList.map(({ node }) => {
          const taikai = node;
          const reference = isAnchorLink ? `#${node.taikaiId}` : createShishimaiTaikaiPath(node.taikaiId ?? "");
          const filtered = thisNendoEntries.filter(({ node }) => {
            return node.taikaiId === taikai.taikaiId;
          });
          if (filtered.length === 1 && filtered[0].node.name?.indexOf("-") === 0) {
            // special entry representing skipped
            const skippedEntry = {
              name: filtered[0].node.name.substr(1),
              nameSuffix: node.nameSuffix,
            };
            return <EventRow node={skippedEntry} refUrl={reference} skipped={true} key={reference} isClassic={isClassic} />;
          } else if (filtered.length > 0) {
            return (
              filtered.map(({ node }) => {
                return <EventRow node={node} refUrl={reference} skipped={false} key={reference} isClassic={isClassic} />;
              })
            );
          } else {
            const placeholder = {
              name: node.nameOfficial,
              nameSuffix: node.nameSuffix,
            };
            return <EventRow node={placeholder} refUrl={reference} skipped={false} key={reference} isClassic={isClassic} />;
          }
        })}
      </tbody>
    </table>
  );
};

export default ThisNendoTable;

const table: ThemeUICSSObject = {
  borderLeft: "1px solid",
  borderTop: "1px solid",
  borderCollapse: "collapse",
  marginBottom: "2.0rem",

  lineHeight: "table",

  "@media screen and (min-width: 601px)": {
    fontSize: "80%",
  },
  "@media screen and (max-width: 600px)": {
    fontSize: "90%",
  },
};

const head: ThemeUICSSObject = {
  backgroundColor: "#fff6bf", /* bright tone yellow (#FFED3A), opacity 50% */

  "tr": {},
  "th": {
    borderBottom: "1px solid",
    borderRight: "1px solid",
    padding: "8px",
    textAlign: "left",
  },

  "@media screen and (max-width: 600px)": {
    display: "none",
  },
};

const body = {
  backgroundColor: "#FFFFF0",
};

/* tableStyle */
const tablec: ThemeUICSSObject = {
  /* width: 90%; */
  borderLeft: "1px solid",
  borderTop: "1px solid",
  borderCollapse: "collapse",

  marginBottom: "3.5em",

  borderSpacing: 0,
  lineHeight: 1.5,
  letterSpacing: 0,
};

const headc: ThemeUICSSObject = {
  /* theadTrStyle */
  "tr": {
    backgroundColor: "#F0E68C",
  },
  /* thCommonStyle */
  "th": {
    borderBottom: "1px solid",
    borderRight: "1px solid",
    padding: "8px",

    textAlign: "left",
  },
};

const bodyc = {};

function isDateInThisNendo(mydateobj: MyDate | undefined, nendoStr: string) {
  if (!mydateobj) {
    return false;
  }
  const dateobj = mydateobj.getCloseDate();
  if (!dateobj) {
    return false;
  }

  return getNendo(dateobj) === nendoStr;
}

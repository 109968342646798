/** @jsxImportSource theme-ui */

import { ThemeUICSSObject } from "theme-ui";
import { MyDate } from "../utils/datetimeutils";
import EventRow from "./event-row";

interface RecentTableNode {
  name: string | null;
  namePrefix: string | null;
  nameSuffix: string | null;
  canceled: string | null;
  locationName: string | null;
  locationSubName: string | null;
  sources: string | null;

  dateObj?: MyDate;
  endDateObj?: MyDate;
  openTimeObj?: Date;
  startTimeObj?: Date;
  endTimeObj?: Date;
}

type RecentTableProps = {
  data: { node: RecentTableNode }[];
  isClassic?: boolean;
};

const RecentTable: React.FC<RecentTableProps> = (props) => {
  const MAX_NUM_ENTRIES = 5;

  const isClassic = props.isClassic ? true : false;

  const entries = props.data.sort((a, b) => {
    var closeDateA = a.node.dateObj?.getCloseDate();
    var closeDateB = b.node.dateObj?.getCloseDate();
    if (closeDateA && closeDateB) {
      return closeDateB.getTime() - closeDateA.getTime();
    } else if (b) {
      return 1;
    } else if (a) {
      return -1;
    } else {
      return 0;
    }
  }).filter(({ node }) => {
    return node.name?.indexOf("-") !== 0;
  }).filter(({ node }, index) => {
    return index < MAX_NUM_ENTRIES;
  });

  return (
    <table sx={isClassic ? tablec : table}>
      <thead sx={isClassic ? headc : head}>
        <tr>
          <th style={{ width: "30%" }}>名前</th>
          <th style={{ width: "20%" }}>日程</th>
          <th style={{ width: "20%" }}>場所</th>
          <th style={{ width: "30%" }}>参照</th>
        </tr>
      </thead>
      <tbody sx={isClassic ? bodyc : body}>
        {entries.map(({ node }, idx) => <EventRow key={`${node.namePrefix}${node.name}_${idx}`} node={node} skipped={false} isClassic={isClassic} />)}
      </tbody>
    </table>
  );
};

export default RecentTable;

const table: ThemeUICSSObject = {
  borderLeft: "1px solid",
  borderTop: "1px solid",
  borderCollapse: "collapse",
  marginBottom: "2.0rem",

  "@media screen and (min-width: 601px)": {
    fontSize: "80%",
  },
  "@media screen and (max-width: 600px)": {
    fontSize: "95%",
  },
};

const head = {
  backgroundColor: "#F0E68C",

  "tr": {},
  "th": {
    borderBottom: "1px solid",
    borderRight: "1px solid",
    padding: "8px",
  },

  "@media screen and (max-width: 600px)": {
    display: "none",
  },
};

const body = {
  backgroundColor: "#FFFFF0",
};

/* tableStyle */
const tablec: ThemeUICSSObject = {
  /* width: 90%; */
  width: "98%",
  borderLeft: "1px solid",
  borderTop: "1px solid",
  borderCollapse: "collapse",

  borderSpacing: 0,
  lineHeight: 1.5,
  letterSpacing: 0,
};

const headc: ThemeUICSSObject = {
  /* theadTrStyle */
  "tr": {
    backgroundColor: "#F0E68C",
  },
  /* thCommonStyle */
  "th": {
    borderBottom: "1px solid",
    borderRight: "1px solid",
    padding: "8px",

    textAlign: "left",
  },
};

const bodyc = {};

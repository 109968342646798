import { graphql, Link, PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import DOMPurify from "isomorphic-dompurify";
import React from "react";
import ExternalLink from "../components/external-link";
import Global from "../components/global";
import { Header } from "../components/header";
import HistoryList from "../components/history-list";
import Main from "../components/main";
import RecentTable from "../components/recent-table";
import Seo from "../components/seo";
import ThisNendoTable from "../components/this-nendo-table";
import Wrapper from "../components/wrapper";
import { createMyDate, createTime, getNendo, MyDate } from "../utils/datetimeutils";
import { getImageWrapper } from "../utils/imageutils";
import { createTaikaiPath } from "../utils/linkutils";
import * as styles from "./shishimai2.module.css";

interface SdataNodeWithObjects {
  node: {
    taikaiId: string | null;
    namePrefix: string | null;
    name: string | null;
    nameSuffix: string | null;
    myNameSuffix: string | null;
    canceled: string | null;
    date: string | null;
    isFest: number | null;
    endDate: string | null;
    isEndDateFest: number | null;
    openTime: string | null;
    startTime: string | null;
    endTime: string | null;
    locationName: string | null;
    locationSubName: string | null;
    note: string | null;
    sources: string | null;

    dateObj?: MyDate;
    endDateObj?: MyDate;
    openTimeObj?: Date;
    startTimeObj?: Date;
    endTimeObj?: Date;
  };
}

interface VisitListEntry {
  text: string;
  url?: string;
}

const TITLE = "獅子舞大会の一覧";

export const Head = () => <Seo title={TITLE} article={true} />;

const OldShishimai = ({ data }: PageProps<Queries.OldShishimaiQuery>) => {
  const HISTORY_LIST_MAX_NUM = 15;
  const now = new Date();

  const taikaiList = data.allGoogleSmetaSheet.edges.filter(({ node }) => {
    return node.taikaiId && node.nameOfficial;
  });
  const taikaiListByDate = [...taikaiList].sort((a, b) => {
    if (a.node.date != null && b.node.date != null) {
      if (a.node.date < b.node.date) {
        return -1;
      } else if (a.node.date > b.node.date) {
        return 1;
      } else {
        return 0;
      }
    } else if (a.node.date != null) {
      return 1;
    } else if (b.node.date != null) {
      return -1;
    } else {
      return 0;
    }
  });

  const entryList = data.allGoogleSdataSheet.edges.filter(({ node }) => {
    return node.taikaiId && node.name;
  });

  return (
    <>
      <>
        <Global />
        <Header />
        <Main>
          <Wrapper classic={true}>
            <div className={styles.noticeBox}>
              <div>
                注意：こちらは旧版（PC向け）のページです。スマホ対応のページは <Link to="/shishimai/">こちら</Link> をご覧ください。
              </div>
            </div>

            <div id="top" className={styles.wrapper}>
              <div className={styles.leftPane}>
                <div className={styles.menuBox}>
                  <h2 className={styles.menuTitle}>ナビゲーション</h2>
                  <div className={styles.menuContentBox}>
                    <ul className={styles.menuUl}>
                      <li className={styles.menuLi}>
                        <Link to="/index2/">ホーム（旧版）</Link>
                      </li>
                      <li className={styles.menuSelectedLi}>獅子舞大会（旧版）</li>
                      <li className={styles.menuLi}>
                        <Link to="/calendar/">カレンダー</Link>
                      </li>
                      <li className={styles.menuLi}>
                        <Link to="/links/">リンク</Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className={styles.menuBox}>
                  <h2 className={styles.menuTitle}>更新履歴</h2>
                  <HistoryList
                    isClassic={true}
                    data={data.allGoogleShistorySheet.edges}
                    maxItems={HISTORY_LIST_MAX_NUM}
                    isAnchorLink={true}
                    isShishimai={true}
                  />
                </div>

                <div className={styles.menuBox}>
                  <h2 className={styles.menuTitle}>姉妹サイト</h2>
                  <ul className={styles.menuUl}>
                    <li className={styles.menuLi}>
                      <a href="https://myshishilist.wordpress.com/">お正月獅子舞一覧</a>
                    </li>
                  </ul>
                </div>

                <div className={styles.menuBox}>
                  <h2 className={styles.menuTitle}>X（旧ツイッター）</h2>
                  <ul className={styles.menuUl}>
                    <li className={styles.menuLi}>
                      <a href="https://x.com/tabigarasu24">@tabigarasu24</a> でやってます。
                    </li>
                  </ul>
                </div>
              </div>
              <div className={styles.main}>
                <div>
                  <p className={styles.p}>マニアックゾーンへようこそ。</p>
                  <p className={styles.p}>
                    こちらのページでは、全国の催しの中から獅子舞オンリーの大会・発表会の日程をピックアップしました。<br />
                    最初から最後まで全部獅子舞、左を向いても右を向いても獅子舞、などといった、まさに<br />
                    <b>獅子舞好きの獅子舞好きによる獅子舞好きのためのイベント</b> といっても過言ではないでしょう。（注：過言です）<br />
                    <br />
                    <span style={{ fontSize: "1.2em" }}>みんなで獅子舞を見に行こうぜ！</span>
                  </p>
                </div>

                <h2 className={styles.noteH2}>注意事項</h2>
                <ul className={styles.noteUl}>
                  <li className={styles.noteLi}>
                    <strong>ここに掲載されている内容は間違っているかも知れません。</strong>また、悪天候などの理由で中止になることもありますので、<br />
                    お出かけの際は主催者に内容をご確認下さい。
                  </li>
                  <li className={styles.noteLi}>古い情報はリンク切れになっているかも知れませんが、どうしようもないのでご了承下さい。</li>
                  <li className={styles.noteLi}>
                    定期的に開催される催しのみを掲載しています。（過去にまだ第1回しか開催されていないものについては、期待しつつ様子見中。）
                  </li>
                  <li className={styles.noteLi}>
                    個人的趣味で1つ虎舞を含めましたが気にしないでください。あと、よければ最後の<a href="#bangai">番外編</a>もどうぞ。
                  </li>
                </ul>

                <h2 className={styles.h2}>{getNendo(now)}の日程一覧</h2>
                <div style={{ marginLeft: "1em" }}>
                  <p style={{ marginBottom: "1.5em" }}>例年のおおよその日程順に並べています。</p>
                  <ThisNendoTable metadata={taikaiListByDate} data={entryList} today={now} isAnchorLink={true} isClassic={true} />
                </div>

                <h2 className={styles.h2}>各イベントの詳細情報</h2>

                {taikaiList.map(({ node }) => {
                  const taikaiId = node.taikaiId;
                  const entries = entryList.filter(({ node }) => node.taikaiId === taikaiId);

                  const entriesWithObjects: SdataNodeWithObjects[] = entries.map(({ node }) => {
                    const s: SdataNodeWithObjects = {
                      node: node,
                    };
                    s.node.dateObj = createMyDate(node.date, node.isFest);
                    s.node.endDateObj = createMyDate(node.endDate, node.isEndDateFest);
                    s.node.openTimeObj = createTime(node.openTime);
                    s.node.startTimeObj = createTime(node.startTime);
                    s.node.endTimeObj = createTime(node.endTime);
                    return s;
                  });

                  const sanitizedDescription = DOMPurify.sanitize(node.descriptionDefault ?? "");

                  var imageFilePath = node.images;
                  var imageData;
                  if (imageFilePath) {
                    // 'images' field contains path in old website ("shishimai/xxx.jpg"). Convert it to "images/xxx.jpg".
                    imageFilePath = imageFilePath.replace("shishimai/", "images/");

                    const file = data.allFile.edges.find(({ node }) => node.relativePath === imageFilePath);
                    if (file != null) {
                      imageData = getImageWrapper(file.node);
                    }
                  }

                  var visitList: VisitListEntry[] = [];
                  if (node.visitList) {
                    visitList = node.visitList.split("\n").map(v => {
                      const s = v.split("http"); // should work for both http and https
                      if (s.length === 2) {
                        const text = s[0];
                        const url = `http${s[1]}`;
                        return {
                          text: text,
                          url: url,
                        };
                      } else {
                        return {
                          text: v,
                        };
                      }
                    });
                  }

                  return (
                    <div key={node.taikaiId}>
                      <h3 id={node.taikaiId ?? ""} className={styles.h3}>
                        {node.namePrefix}
                        {node.nameOfficial}
                        {node.nameSuffix}
                        {node.myNameSuffixDefault ? <span className={styles.h3MySuffix}>{node.myNameSuffixDefault}</span> : ""}
                      </h3>
                      <div className={styles.itemBlock}>
                        {sanitizedDescription
                          && (
                            <div style={{ marginRight: "24px" }}>
                              {imageData && (
                                <GatsbyImage
                                  image={imageData}
                                  alt={`${node.namePrefix || ""}${node.nameOfficial || ""}${node.nameSuffix || ""}`}
                                  style={{ marginLeft: "2em", float: "right" }}
                                />
                              )}
                              <h4 className={styles.h4}>概要</h4>
                              <div style={{ marginLeft: "1em" }} dangerouslySetInnerHTML={{ __html: sanitizedDescription }}></div>
                              {visitList.length > 0
                                && (
                                  <div style={{ marginTop: "1em", marginLeft: "1em" }}>
                                    過去の見学メモはこちら →{" "}
                                    <ul className={styles.visitedul}>
                                      {visitList.map(v => {
                                        if (v.url) {
                                          const url = migrateVisitUrl(v.url);
                                          if (url.startsWith("http")) {
                                            // external site
                                            return (
                                              <li className={styles.visitli} key={url}>
                                                <ExternalLink url={url}>{v.text}</ExternalLink>
                                              </li>
                                            );
                                          } else {
                                            return (
                                              <li className={styles.visitli} key={url}>
                                                <Link to={url}>{v.text}</Link>
                                              </li>
                                            );
                                          }
                                        } else {
                                          return <li className={styles.visitli} key={v.text}>{v.text}</li>;
                                        }
                                      })}
                                    </ul>
                                  </div>
                                )}
                              {imageData && <div style={{ clear: "right" }}></div>}
                            </div>
                          )}
                        <h4 className={styles.h4}>直近の開催日程</h4>
                        <div className={styles.tableDiv}>
                          <RecentTable data={entriesWithObjects} isClassic={true} />
                        </div>
                        {node.noteDefault
                          && (
                            <>
                              <h3 className={styles.h3}>備考</h3>
                              <div className={styles.paragraph}>{node.noteDefault}</div>
                            </>
                          )}
                      </div>
                    </div>
                  );
                })}

                <h2 className={styles.h2} id="bangai">番外編</h2>
                <div style={{ margin: "0 0 2em 0.5em" }}>
                  獅子舞オンリーというわけではないけれど、“シシ分”（獅子舞成分）が豊富に含まれているイベントやお祭りを独断と偏見で紹介。
                </div>

                <h3 className={styles.h3}>九十九里町郷土芸能大会</h3>
                <div className={styles.itemBlock}>
                  <p>
                    ※ 日程については <Link to={createTaikaiPath("kujukurimachi_kyodo")}>九十九里町郷土芸能大会</Link> のページを参照
                  </p>
                  <p>
                    3年に1度の開催。2017年に見学に行ったのですが、9団体のうち7団体が獅子舞・羯鼓舞という密度の濃い内容でした。<br />
                    土日の2日間に渡って開催されるのも素晴らしい。（どちらの日も同じ団体が出演されますが、演目が異なるようです。）<br />
                    アクセスは千葉駅から高速バス（九十九里ライナー）で「九十九里町役場」下車が便利だと思います。駐車場あり。
                  </p>
                  <p>
                    2017年の動画はこちら →{" "}
                    <ExternalLink url="https://myshishilist.wordpress.com/2017/10/22/2017-10-22_kujukuri/">
                      第9回九十九里町郷土芸能大会 （2017年10月22日）
                    </ExternalLink>
                  </p>
                </div>

                <h3 className={styles.h3}>香取市里神楽演舞会</h3>
                <div className={styles.itemBlock}>
                  <p>
                    ※ 日程については <Link to={createTaikaiPath("katorishi_kagura")}>香取市里神楽演舞会</Link> のページを参照
                  </p>
                  <p>
                    2年に1度の開催。これまで2012年と2018年に見学に行きました。<br />
                    神楽の公演なので獅子舞とは限らないのですが、2018年のプログラムは7団体のうち4団体が神楽獅子、1団体が三匹獅子舞と、こちらも密度が濃かったです。<br />
                    会場は年によって異なるようですので注意。
                  </p>
                  <p>
                    2018年の動画（一部）はこちら →{" "}
                    <ExternalLink url="https://myshishilist.wordpress.com/2018/03/11/2018-03-11_katorishi_satokagura/">
                      第五回香取市里神楽演舞会 （2018年3月11日）
                    </ExternalLink>
                  </p>
                </div>

                <h3 className={styles.h3}>常陸國總社宮例大祭（石岡のおまつり）</h3>
                <div className={styles.itemBlock}>
                  <p>
                    毎年9月中旬の3連休に開催されます。たいていのお祭りが1～2日のところ、3日間ですよ！<br />
                    多くの山車にまじってたくさんの「幌獅子」が町を練り歩きます。Wikipediaによると32台（…ってそんなにたくさんいたかなあ）。<br />
                    茨城県内で幌獅子が出る祭礼は他にもありますが、これだけ大規模なのは石岡のみではないでしょうか。<br />
                    場所はJR石岡駅周辺。駐車場あり。
                  </p>
                </div>

                <h3 className={styles.h3}>獅子たちの里　三木まんで願。</h3>
                <div className={styles.itemBlock}>
                  <p>
                    「獅子たちの里」こと香川県三木町の秋大祭。<br />
                    残念ながらまだ見学に行ったことはありませんが、写真を見る限り多くの獅子舞が参加するようです。<br />
                    三木町の公式Webサイトに情報が掲載されるようなので、気になる方は定期的にチェックすべし。<br />
                    ついでに少しやりすぎ(?!)な <ExternalLink url="https://www.youtube.com/watch?v=3FCdE-3LmUc">町公式PV</ExternalLink> もチェックすべし。
                  </p>
                </div>

                <h3 className={styles.h3}>ひみまつり</h3>
                <div className={styles.itemBlock}>
                  <p>
                    富山県氷見市にて8月上旬に開催される花火大会＋各種イベント。<br />
                    夕方に「獅子舞フェスティバル」があり、市内外あわせて3～４団体が演舞を披露されるようです。<br />
                    市外からも参加があるのはすごい。ポスターにも毎年ちゃんと獅子舞のイラストが入っていますね :-)
                  </p>
                </div>

                <h3 className={styles.h3}>富山新港花火大会</h3>
                <div className={styles.itemBlock}>
                  <p>
                    7月下旬に富山県射水市にて行われる花火大会。こちらも打ち上げの前に2時間ほど獅子舞の演舞があるようです。<br />
                    獅子舞と花火が1日に同時に見られておトクな感じ。<br />
                    ちなみに平成28年までは「射水市獅子舞競演会」として花火大会とは別に開催されていたようです。
                  </p>
                </div>

                <h3 className={styles.h3}>善通寺空海まつり</h3>
                <div className={styles.itemBlock}>
                  <p>
                    善通寺（香川県善通寺市）にて毎年11月3日に行われる「佐伯祖廟御法楽法要」とその関連行事。<br />
                    行事の1つに「獅子舞大会」があり、市内約40の獅子組が舞を奉納するそうです。<br />
                    1つの寺院に40組って…すごい数だな。ネット上の写真を見る限り一斉演舞なのでしょうか。
                  </p>
                </div>

                <h2 className={styles.footerH2}>その他</h2>
                <div className={styles.footer}>
                  <p className={styles.p}>
                    このサイトへのリンクはご自由にどうぞ。何かありましたらサイト製作者のやのけん (yanoken850p アットマーク gmail.com) まで。<br />
                    サイトについて詳しくは <Link to="/about/">こちらのページ</Link> もご覧ください。
                  </p>
                </div>
              </div>
            </div>
          </Wrapper>
        </Main>
      </>
    </>
  );
};

export default OldShishimai;

function migrateVisitUrl(origUrl: string): string {
  if (origUrl.indexOf("minamishinshu_h22") > 0) {
    return "/blog/minamishinshu_h22/";
  } else if (origUrl.indexOf("shishishibai_h27") > 0) {
    return "/blog/shishishibai_h27/";
  } else {
    return origUrl;
  }
}

export const query = graphql`
  query OldShishimai {
    allGoogleSmetaSheet(sort: {fields: prefectureId, order: ASC}, filter: {nameOfficial: {regex: "/^[^#?]/"}}) {
      edges {
        node {
          taikaiId
          namePrefix
          nameOfficial
          nameSuffix
          myNameSuffixDefault
          descriptionDefault
          images
          noteDefault
          visitList
          prefectureId
          date
        }
      }
    }
    allGoogleSdataSheet(filter: {name: {regex: "/^[^#?]/"}}) {
      edges {
        node {
          taikaiId
          namePrefix
          name
          nameSuffix
          myNameSuffix
          canceled
          date
          isFest
          endDate
          isEndDateFest
          openTime
          startTime
          endTime
          locationName
          locationSubName
          note
          sources
        }
      }
    }
    allGoogleShistorySheet {
      edges {
        node {
          updateDate
          isNew
          taikaiId
          title
          startDate
          endDate
        }
      }
    }
    allFile(filter: {relativePath: {regex: "/^images.+.jpg/"}}) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(
              layout: FIXED
              placeholder: BLURRED
              height: 240
            )
          }
        }
      }
    }
  }
`;
